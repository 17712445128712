.new-requests-details {
  &__wrapper {
    margin-block: 2rem;
  }
  &__title {
    color: var(--mui-palette-grayDark-color);
    font-size: 1.8rem !important;
    margin-bottom: 2rem !important;
    font-weight: 500 !important;
  }
}
