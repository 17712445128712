.modal {
  position: fixed;
  max-width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99995;
  width: 90rem;
  padding-inline: 2rem;
  overflow: hidden;

  &__title {
    color: var(--mui-palette-grayDark-color);
    font-weight: bold !important;
    font-size: 1.8rem !important;
  }
  &__close-icon {
    cursor: pointer;
  }

  hr {
    border: 1px solid var(--mui-palette-borders-colorSoft6);
  }
}

.backdrop {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  backdrop-filter: unset;
  background-color: rgba(#45897f, 0.5);
  overflow: hidden;
  &--blurry {
    background-color: transparent;
    backdrop-filter: blur(25rem);
  }
}
