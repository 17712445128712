.license-status__tooltip {
  position: absolute;
  top: 2rem;
  left: 50%;
  transform: translateX(-50%);
  color: var(--mui-palette-base-black);
  text-align: center;
  background-color: var(--mui-palette-base-white);
  width: 20rem;
  padding: 0.5rem 1.5rem;
  border-radius: 5px;
  opacity: 0;
  visibility: hidden;
  z-index: 9;

  &--delivery-progress {
    border: 1px solid var(--mui-palette-base-watting);
    &::after {
      border-bottom-color: var(--mui-palette-base-watting);
    }
  }
  &--tooltip-black {
    border: 1px solid var(--mui-palette-graySoft-color3);

    &::after {
      border-bottom-color: var(--mui-palette-graySoft-color3);
    }
  }
  &--tooltip-green-light {
    border: 1px solid var(--mui-palette-secondary-color);

    &::after {
      border-bottom-color: var(--mui-palette-secondary-color);
    }
  }
  &--tooltip-orange {
    border: 1px solid var(--mui-palette-base-status-orange);
    &::after {
      border-bottom-color: var(--mui-palette-base-status-orange);
    }
  }
  &--tooltip-brown {
    border: 1px solid var(--mui-palette-base-status-brown);

    &::after {
      border-bottom-color: var(--mui-palette-base-status-brown);
    }
  }
  &--tooltip-blue-dark {
    border: 1px solid var(--mui-palette-base-darkBlue);

    &::after {
      border-bottom-color: var(--mui-palette-base-darkBlue);
    }
  }
  &--tooltip-blue-light {
    border: 1px solid var(--mui-palette-base-status-infoDark);

    &::after {
      border-bottom-color: var(--mui-palette-base-status-infoDark);
    }
  }

  &--tooltip-blue {
    border: 1px solid var(--mui-palette-base-infoDark);
  }
  &--not-found {
    border: 1px solid var(--mui-palette-primary-colorSoft4);
  }

  &-trigger {
    margin-left: 1px;
    position: relative;
    cursor: pointer;
  }
  &-text {
    white-space: pre-wrap;
    text-align: start;
    line-height: 1.7;
    font-size: 1.1rem;
  }
  &::before,
  &::after {
    content: "";
    position: absolute;
    top: -2rem;
    left: 50%;
    transform: translateX(-50%);
    border-style: solid;
    border-width: 10px;
    border-top-color: transparent;
    border-right-color: transparent;
    border-left-color: transparent;
  }
  &::before {
    border-bottom-color: var(--mui-palette-base-white);
    z-index: 9;
  }
  &::after {
    border-bottom-color: var(--mui-palette-base-infoDark);
    transform: translateX(-50%) translateY(-1px) scale(1.1);
  }
}

.license-status__tooltip-trigger:hover .license-status__tooltip {
  animation: showTooltip 0.1s ease forwards;
}

@keyframes showTooltip {
  0% {
    opacity: 0;
    visibility: hidden;
    top: 2rem;
  }
  100% {
    opacity: 1;
    visibility: visible;
    top: 3.5rem;
  }
}
