.smart-search-list {
  position: absolute;
  width: 100%;
  transform: translateY(1rem);
  border-radius: 3px;
  padding: 1rem;
  z-index: 9;
  background-color: var(--mui-palette-background-primary);
  border: 1px solid var(--mui-palette-borders-primary);
  font-size: 1.3rem;
  display: flex;
  flex-direction: column;
  height: 18rem;
  overflow-y: scroll;
  top: calc(4rem + 3rem);

  &--empty {
    height: 10rem;
  }

  &__item {
    transition: all 0.5s ease;
    cursor: pointer;
    padding: 1rem;
    border-radius: 3px;
    &:hover {
      background-color: var(--mui-palette-background-colorSoft8);
    }
  }
}
