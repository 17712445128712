.dashboard-user-info {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.6rem;
  &__settings {
    cursor: pointer;
  }
  &__details {
    position: relative;
    margin-inline-start: 1rem;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      inset-inline-end: -1rem;
      width: 1px;
      height: 100%;
      background-color: #e0e0e1;
    }
    &-item {
      display: flex;
      align-items: center;
      gap: 4px;
      p,
      span {
        font-size: 1.4rem !important;
        color: var(--mui-palette-grayDark-color3);
      }
    }
  }
  &__wallet {
    &-points {
      color: var(--mui-palette-grayDark-color11);
      font-size: 1.4rem !important;
      font-weight: bolder;
      display: flex;
      align-items: center;
    }
  }
  &__user {
    &-name {
      color: var(--mui-palette-base-black);
      font-size: 1.6rem;
      font-weight: bolder;
      margin-inline-start: 0.5rem;
    }
  }
}
