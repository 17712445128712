.logo-wrapper {
  width: 70px;
  height: 37px;
  padding: 0.8rem 1.5rem 0rem 0rem;
  gap: 0px;
  border-radius: 0.4rem;
  border: 1px solid #E1E4E5;
  // opacity: 0;
  margin-right: 10px;
}

