.uploader {
  &__wrapper {
    position: relative;
    & input[type="file"] {
      position: absolute;
      opacity: 0;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 9;
    }
    &.dragover {
      background-color: #d9eeff;
    }
    &--disabled {
      filter: grayscale(1);
      cursor: not-allowed;
    }
  }
}
