.payment-summary-card {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: -25%;
    width: 1px;
    height: 90%;
    background: #eeeeee;
    @media screen and (max-width: 900px) {
      display: none;
    }
  }
}
