.custom-time-picker .MuiOutlinedInput-root {
  border: 1px solid #b6d0cd !important;
  background-color: #fbfdfd !important;
  border-color: #b9cecc !important;
  height: 4rem !important;
}
.custom-time-picker
  .MuiOutlinedInput-root:hover
  .MuiOutlinedInput-notchedOutline {
  border-color: #b6d0cd !important;
}

.custom-time-picker .MuiOutlinedInput-input::placeholder {
  font-size: 16px;
  font-weight: 200;
}

.custom-time-picker .MuiOutlinedInput-input {
  font-size: 16px;
  font-weight: 200;
}
.custom-time-picker
  > .MuiOutlinedInput-root
  > .MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.custom-time-picker.custom-time-picker--invalid .MuiOutlinedInput-root {
  background-color: var(--mui-palette-base-danger) !important;
  border: 1px solid var(--mui-palette-base-dangerBorder) !important;
}

.custom-time-picker.custom-time-picker--disabled .MuiOutlinedInput-root {
  background-color: var(--mui-palette-background-colorSoft2) !important;
  border: 1px solid var(--mui-palette-graySoft-color) !important;
  opacity: 1 !important;
  font-weight: 500 !important;
  font-size: 1.6rem !important;
  margin-top: 1px;
}

.custom-time-picker > label {
  font-size: 13px;
}

.custom-time-picker .MuiInputBase-input {
  height: 2.5rem !important;
}
