.restore-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;

  &__img path {
    stroke: var(--mui-palette-primary-main);
  }

  &__text {
    color: var(--mui-palette-primary-main);
  }

  &:disabled &__img path {
    stroke: var(--mui-palette-graySoft-color);
  }

  &:disabled &__text {
    color: var(--mui-palette-graySoft-color);
  }
}
