.base-radio {
  position: relative;
  background-color: var(--mui-palette-base-white);
  border: 2px solid var(--mui-palette-borders-primary);
  padding: 0.5rem 1.5rem;
  border-radius: 0.5rem;
  height: 4rem;
  display: flex;
  flex-wrap: wrap;

  &--disabled {
    background-color: #fafafa;
    border: 1px solid #7e7d7dc7;
  }

  &__label {
    font-weight: 500 !important;
    color: var(--mui-palette-grayDark-color);
    opacity: 0.52;
  }

  &--invalid {
    background-color: var(--mui-palette-base-danger);
    border: 1px solid var(--mui-palette-base-dangerBorder);
  }
}
