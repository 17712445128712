.base-alert {
  min-height: 4.4rem;

  &--info {
    background-color: var(--mui-palette-base-infoBackground) !important;
    color: var(--mui-palette-base-black) !important;
    border: 1px solid var(--mui-palette-base-infoBorder2) !important;
  }
  &--error {
    background-color: var(--mui-palette-base-danger) !important;
    color: var(--mui-palette-base-black) !important;
    border: 1px solid var(--mui-palette-base-dangerBorder) !important;
  }
  &--warning {
    background-color: var(--mui-palette-base-warning) !important;
    border: 1px solid var(--mui-palette-base-warningBorder) !important;
  }
  &--success {
    background-color: var(--mui-palette-base-success) !important;
    color: var(--mui-palette-grayDark-color8) !important;
    border: 1px solid var(--mui-palette-base-successBorder) !important;
  }
  &__text {
    & > * {
      line-height: inherit;
      font-size: inherit !important;
    }
    a {
      font-weight: 500 !important;
      color: var(--mui-palette-primary-main) !important;
    }
  }
}
