.invoice-status {
  margin-inline-start: 1rem;
  gap: 1rem;
  font-size: 1.4rem !important;

  &__text {
    position: relative;
    font-weight: bold !important;
    color: var(--mui-palette-secondary-color);
    font-size: 1.4rem !important;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      inset-inline-start: -1.4rem;
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      transform: translateY(-50%);
      opacity: 0.5;
      background-color: var(--mui-palette-secondary-color2);
    }
  }
}
