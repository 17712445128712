.base-input {
  position: relative;
  border-radius: 5px;
  font-size: 1.4rem;
  width: 100%;
  display: block;
  height: 4rem;
  background-color: var(--mui-palette-background-primary);
  border: 1px solid var(--mui-palette-borders-primary);
  padding: 10px 8px;

  &:focus {
    outline: 1px solid var(--mui-palette-borders-primary);
  }

  &::-webkit-input-placeholder {
    color: var(--mui-palette-graySoft-color);
    font-weight: 500;
    font-size: 1.4rem;
    font-family: "FuhosatFont", sans-serif;
    @media screen and (max-width: 60rem) {
      font-size: 1rem;
    }
  }

  &--invalid {
    background-color: var(--mui-palette-base-danger);
    border: 1px solid var(--mui-palette-base-dangerBorder);
  }

  &__icon {
    width: 1rem;
    position: absolute !important;
    top: 50%;
    inset-inline-end: 1.5rem;
    transform: translateY(-50%);
    &--password {
      inset-inline-end: 2.5rem;
    }
    img {
      width: 2rem;
    }
  }
  &--disabled {
    font-family: "FuhosatFont", sans-serif;
    font-weight: 500;
    font-size: 1.4rem;
    background-color: var(--mui-palette-background-colorSoft2);
    opacity: 1;
    color: #525252cc;
    cursor: not-allowed;
  }
}
