.capture {
  background: var(--mui-palette-base-white);
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
  border: 2px solid var(--mui-palette-base-white);
  position: relative;
  margin-left: 5rem;

  &::before,
  &::after {
    content: "";
    position: absolute;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &::before {
    width: 95%;
    height: 95%;
    background: var(--mui-palette-graySoft-color);
  }
  &::after {
    width: 70%;
    height: 70%;
    background: var(--mui-palette-base-white);
  }
}
