.company-data {
  &__wrapper {
    margin-block: 2rem;
    margin-bottom: 8rem;
    margin-top: 4rem;
  }
  &__title {
    color: var(--mui-palette-grayDark-color);
    font-size: 1.8rem !important;
    margin-bottom: 4rem !important;
    font-weight: 500 !important;
  }
  &__label {
    color: var(--mui-palette-black);
    font-size: 1.4rem !important;
    margin-bottom: 3px !important;
    font-weight: 500 !important;
  }
  &__value {
    color: var(--mui-palette-primary-colorSoft4);
    font-size: 1.6rem !important;
  }

  hr {
    border: 1px solid var(--mui-palette-borders-colorSoft2);
  }
}
