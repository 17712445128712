.sidemenu {
  max-height: 100vh;
  top: 0;
  overflow-y: scroll;
  transition: all 0.5s ease;
  background: linear-gradient(
      265deg,
      var(--mui-palette-primary-main) 50%,
      var(--mui-palette-secondary-main) 150%
    )
    0% 0% no-repeat;
  a {
    color: var(--mui-palette-base-white);
  }
  &__list {
    padding-bottom: 30px !important;
    &-item {
      border-radius: 3px;
      cursor: pointer;
    }
    &-icon {
      min-width: 3.2rem !important;
    }
    &-label {
      span {
        font-size: 1.6rem;
        font-weight: bold !important;
      }
    }
  }

  &__link {
    display: block;
    &[data-item-id^="child"] {
      margin-inline-start: 2rem;
      > li {
        text-align: center;
      }
    }
    &--active {
      border-radius: 7px;
      background-color: var(--mui-palette-base-white);
      color: var(--mui-palette-primary-main) !important;
    }

    &:not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  &__logout {
    position: relative;
    margin-top: auto;
    &::before {
      content: "";
      position: absolute;
      top: -2rem;
      width: 100%;
      height: 2px;
      background-color: var(--mui-palette-base-white);
    }
    &-link {
      font-weight: bold !important;
      font-size: 1.6rem;
    }
  }
}
