.raise-result {
  &__btn {
    background: none;
    border: none;
    appearance: none;
    cursor: pointer;
    &:disabled {
      color: var(--mui-palette-grayDark-color2);
    }
  }
  &__edit-icon--disabled {
    g path {
      stroke: var(--mui-palette-grayDark-color2) !important;
    }
  }
}
