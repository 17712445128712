.base-btn {
  background-image: linear-gradient(
    234deg,
    var(--mui-palette-primary-main) 70%,
    var(--mui-palette-secondary-main) 100%
  );

  background-size: 200%;

  &--secondary,
  &--disabled,
  &--transparent,
  &--white {
    background-image: none;
    background-size: auto;
  }

  &--disabled-fancy {
    background-image: linear-gradient(
      135deg,
      var(--mui-palette-grayDark-color10) 0%,
      var(--mui-palette-graySoft-color7) 50%
    );

    color: var(--mui-palette-base-white) !important;
  }

  &:hover {
    background-position: right !important;
  }

  &--primary:hover {
    background-image: linear-gradient(
      234deg,
      var(--mui-palette-primary-main) 70%,
      var(--mui-palette-secondary-main) 100%
    );
  }
  &--disabled-fancy:hover {
    background-color: var(--mui-palette-primary-color) !important;
  }
  &--white:hover {
    background-color: var(--mui-palette-base-white) !important;
    color: var(--mui-palette-base-black) !important;
  }
  &--secondary:hover {
    background-color: #f5faf9 !important;
    color: var(--mui-palette-primary-main) !important;
  }
}
