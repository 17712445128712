.base-calendar.rmdp-wrapper {
  border: 1px solid #b6d0cd !important;
}

.rmdp-input {
  height: 4rem !important;
  width: 100%;
  padding: 10px;
  margin: 0px !important;
  border: 1px solid #b6d0cd !important;
  background-color: #fbfdfd !important;
}

.base-calendar--disabled .rmdp-input {
  font-family: "FuhosatFont", sans-serif !important;
  font-weight: 500 !important;
  font-size: 1.4rem !important;
  background-color: #fafafb !important;
  opacity: 1 !important;
  color: #b1b1b1 !important;
  cursor: not-allowed !important;
  pointer-events: none !important;
  user-select: none !important;
}

.base-calendar--disabled .rmdp-input::placeholder {
  color: #b1b1b1 !important;
}

.error .rmdp-input {
  background-color: var(--mui-palette-base-danger) !important;
  border: 1px solid var(--mui-palette-base-dangerBorder) !important;
}

.base-calendar .rmdp-calendar {
  padding: 10px !important;
}

.rmdp-container {
  width: 100%;
}

.rmdp-container svg {
  color: #1f766c !important;
  margin-left: 10px;
}

.base-calendar--disabled .rmdp-container svg {
  color: #b1b1b1 !important;
  margin-left: 10px;
}

.rmdp-week-day {
  color: #1f766c !important;
}

.rmdp-day-picker > div {
  min-width: 37rem;
}

.base-calendar .rmdp-header,
.base-calendar .rmdp-panel-header {
  margin-top: 10px !important;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 500;
  color: #1f766c !important;
}

.rmdp-header-values {
  color: #1f766c !important;
}

.base-calendar .rmdp-header > div {
  margin: auto;
  width: 70%;
}

.base-calendar .rmdp-time-picker {
  border-top: 1px solid #b6d0cd;
  margin-bottom: 0 !important;
}

.rmdp-arrow-container:hover {
  background-color: #1f766c !important;
  box-shadow: 0 0 3px #8798ad !important;
}

.rmdp-rtl .rmdp-right i {
  margin-left: 1px;
  margin-right: 0;
  margin-top: 7px;
}

.rmdp-rtl .rmdp-left i {
  margin-top: 7px;
}

.rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: #77c728 !important;
  box-shadow: none;
}

.rmdp-day span {
  border-radius: 30%;
}

.rmdp-day.rmdp-today span {
  background-color: white;
  color: black;
}

.rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
  background-color: #9de655 !important;
}

.base-calendar .rmdp-panel-body li {
  border-radius: 5px;
}

.base-calendar .rmdp-border-top {
  border-top: 1px solid #b6d0cd !important;
}

.base-calendar .rmdp-border-bottom {
  border-bottom: 1px solid #b6d0cd !important;
}

.base-calendar .rmdp-border-left {
  border-left: 1px solid #b6d0cd !important;
}

.base-calendar .rmdp-border-right {
  border-right: 1px solid #b6d0cd !important;
}

.rmdp-mobile .rmdp-action-button {
  color: #1f766c !important;
}

.rmdp-arrow,
.rmdp-mobile .rmdp-arrow {
  border-color: #1f766c;
}

.rmdp-mobile .rmdp-week-day,
.rmdp-week-day {
  font-size: 10px;
}
