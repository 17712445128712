.uploader-progress {
  position: relative;
  border: 1px solid var(--mui-palette-primary-colorSoft14);
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1.5rem;

  &__close {
    position: absolute;
    top: 1.5rem;
    left: 1.5rem;
    cursor: pointer;

    svg {
      font-size: 2rem;
      path {
        fill: var(--mui-palette-primary-colorSoft14);
      }
    }
  }

  &--disabled,
  &--disabled &__close {
    cursor: not-allowed;
  }

  &__info {
    flex: 1;

    display: flex;
    justify-content: space-between;
    align-items: center;

    progress {
      direction: ltr;
    }
    progress::-webkit-progress-value {
      background: var(--mui-palette-primary-colorSoft14);
    }
  }
  &__bar {
    border: 1px solid var(--mui-palette-primary-colorSoft14);
    height: 1.7rem;
    border-radius: 3rem;
    position: relative;
    margin-top: 2rem;
    flex: 1;
    &--label {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: var(--mui-palette-base-white);
      color: var(--mui-palette-primary-colorSoft14);
      width: 100%;
      height: 100%;
      z-index: 2;
      text-align: center;
    }
    &--process {
      position: absolute;
      inset-inline-end: 0;
      top: 0;
      height: 100%;
      width: 10%;
      background-color: var(--mui-palette-primary-colorSoft14);
      border-radius: 3rem;
    }
  }
}
