.checkup-timeline {
  position: relative;
  width: 100%;
  direction: rtl;

  &__wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  &__circle {
    width: 3.2rem;
    height: 3.2rem;
    border-radius: 50%;
    background: var(--mui-palette-base-white);
    color: var(--mui-palette-graySoft-color);
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--mui-palette-graySoft-color);
    z-index: 2;
    font-size: 12px;
    font-weight: bold;
    position: relative;
    overflow: hidden;
  }
  &__icon-done {
    position: absolute;
    top: 0;
    inset-inline-end: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease;
    transform: scale(0);
  }
  &__wrapper--active &__circle {
    border: 1px solid var(--mui-palette-secondary-main);
    color: var(--mui-palette-secondary-main);
  }
  &__wrapper--done &__circle {
    border: 1px solid var(--mui-palette-secondary-main);
  }
  &__wrapper--done &__icon-done {
    background: var(--mui-palette-secondary-main);
    border: 1px solid var(--mui-palette-secondary-main);
    transform: scale(1);
  }
  &__label {
    width: max-content;
    inset-inline-start: -100%;
    top: 110%;
    font-size: 1.5rem !important;
    color: var(--mui-palette-graySoft-color2);
    &--sm {
      inset-inline-start: -50%;
    }
  }
  &__label--active {
    color: var(--mui-palette-secondary-main);
  }
}

.uploader-progress {
  position: relative;
  border: 1px solid var(--mui-palette-primary-colorSoft14);
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1.5rem;

  &__bar {
    height: 1rem;
    border-radius: 3rem;
    position: relative;
    margin-top: 2rem;
    flex: 1;
    background: var(--mui-palette-background-disabled);
    &--process {
      position: absolute;
      inset-inline-end: 0;
      top: 0;
      height: 100%;
      background-color: var(--mui-palette-secondary-main);
      border-radius: 3rem;
    }
  }
}

.progress-label {
  font-size: 1.5rem !important;
  color: var(--mui-palette-graySoft-color2);
}