.base-attachment {
  position: relative;
  border: 1px solid #449a91;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1.5rem;
  cursor: pointer;
  width: 100%;

  &__info {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
