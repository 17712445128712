.MuiInputBase-input {
  height: 3rem !important;
  padding: 5px 10px !important;
}

.MuiFormControl-root,
.MuiFormControl-root > * + * {
  width: 100%;
}
.MuiTypography-root {
  font-size: 1.6rem;
}

.MuiMenuItem-root {
  font-size: 1.2rem !important;
}

.MuiCheckbox-root,
.MuiRadio-root {
  transform: scale(1.5) !important;
}

.MuiAccordionSummary-content {
  margin: 0 !important;
}
.MuiAlert-message {
  overflow: hidden !important;
}

.muirtl-1mu1xlv-MuiTypography-root,
.muirtl-1s35bcw-MuiTypography-root {
  direction: unset !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

.date-items-wrapper .muirtl-dmmspl-MuiFormGroup-root {
  display: inline;
}
.rmdp-input {
  font-family: "FuhosatFont", sans-serif;
}

header .active p {
  color: #00503f;
  font-weight: 700;
}
header .active {
  position: relative;
}

header .active::after {
  content: "";
  position: absolute;
  background: transparent url("../assets/icons/intersection.png") no-repeat;
  bottom: -12px;
  width: 100%;
  height: 12px;
  background-size: contain;
  background-position: center;
}
.slide-menu a {
  color: #000000;
  position: relative;
  margin-right: 20px;
}

.slide-menu a span {
  font-weight: 500;
}

.slide-menu a.active span {
  color: #00503f;
}
.slide-menu a.active::after {
  content: "";
  position: absolute;
  background: transparent url("../assets/icons/intersection.png") no-repeat;
  right: -15px;
  width: 10px;
  height: 10px;
  background-size: contain;
  background-position: center;
  top: 4px;
}

.side-nav {
  top: 90px !important;
  box-shadow: none !important;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  height: 80% !important;
}

.instruction-unorderedlist {
  margin-right: 3rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
  list-style: disc;
}
