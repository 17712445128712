.base-camera {
  position: relative;
  &__title {
    font-size: 1.8rem !important;
    font-weight: bold !important;
    color: var(--mui-palette-grayDark-color);
  }

  hr {
    border-color: var(--mui-palette-borders-colorSoft6);
  }

  &__image {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }

  &__camera {
    overflow: hidden;
    height: 50rem;
    video {
      width: 100% !important;
      height: auto !important;
      aspect-ratio: 2/1;
    }
  }
}
