.clipboard {
  position: relative;
  &__tooltip {
    position: absolute;
    background: white;
    padding: 2px 8px;
    width: 70px;
    border-radius: 3px;
    color: #45897f;
    font-weight: 500;
    border: 1px solid #eee;
    text-align: center;
    top: 2rem;
    right: 3rem;
    opacity: 0;
    &--copied {
      animation: copied 0.5s ease backwards;
    }
  }
}

@keyframes copied {
  0% {
    top: 2rem;
    right: 4rem;
    opacity: 0;
  }
  100% {
    top: 0rem;
    right: 4rem;
    opacity: 1;
  }
}
