.base-table {
  &__details-link:hover &__details-arrow div {
    border-left: 2px solid var(--mui-palette-primary-main);
    border-bottom: 2px solid var(--mui-palette-primary-main);
  }
  &__details-link:hover &__details-arrow span {
    width: 2rem;
    background-color: var(--mui-palette-primary-main);
  }
  &__details-link:hover &__details-wrapper p {
    transform: translateX(1rem);
    color: var(--mui-palette-primary-main);
  }
  &__details-wrapper {
    p {
      color: var(--mui-palette-grayDark-color1);
      transition: all 0.5s ease;
    }
  }
  &__details-arrow {
    position: relative;
    div {
      width: 1rem;
      height: 1rem;
      background: transparent;
      border-left: 2px solid var(--mui-palette-base-black);
      border-bottom: 2px solid var(--mui-palette-base-black);
      transform: rotate(45deg);
    }
    span {
      position: absolute;
      display: block;
      top: 50%;
      transform: translateY(-50%);
      inset-inline-end: 0;
      background: var(--mui-palette-base-black);
      width: 0px;
      height: 2px;
      transition: all 0.5s ease;
    }
  }
}
