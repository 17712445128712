.filter {
  width: min(40rem, 100%);
  &__wrapper {
    height: 4rem;
  }
  &__item {
    width: calc(100% / 3);
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--mui-palette-base-white);

    &:not(:nth-child(2)) {
      border: 1px solid var(--mui-palette-primary-colorSoft6);
    }
    &:nth-child(2) {
      border-top: 1px solid var(--mui-palette-primary-colorSoft6);
      border-bottom: 1px solid var(--mui-palette-primary-colorSoft6);
    }
    &-label {
      font-size: 1.4rem !important;
      font-weight: 400 !important;
    }
    &--active {
      background-color: var(--mui-palette-background-colorSoft9);
    }
    &--active &-label {
      font-weight: 500 !important;
    }
  }
}
