.license-type {
  font-size: 1.6rem;
  display: inline-block;
  border-radius: 5px;
  width: 10.8rem;
  text-align: center;

  &__text {
    position: relative;
    color: inherit;
    font-weight: bold !important;
    font-size: 1.4rem !important;
  }

  &--normal &__text,
  &--1 &__text {
    color: var(--mui-palette-text-tags-blueDark);
  }

  &--urgent &__text,
  &--2 &__text {
    color: var(--mui-palette-text-tags-orangeDark);
  }
  &--lab &__text {
    color: var(--mui-palette-text-tags-blueSoft);
  }

  &--field &__text {
    color: var(--mui-palette-text-tags-orangeSoft);
  }
}
