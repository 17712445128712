.license-status {
  margin-inline-start: 1rem;
  gap: 1rem;
  font-size: 1.4rem !important;
  color: var(--mui-palette-grayDark-color5);

  &__text {
    position: relative;
    color: inherit;
    font-weight: bold !important;
    font-size: 1.4rem !important;

    // SUCCEED {maybe will change}
    &--APPROVED,
    &--REFUND,
    &--SUCCEED,
    &--ISSUED,
    &--COMPLETED,
    &--active,
    &--IMPORTED,
    &--USED,
    &--undefined,
    &--CREATED,
    &--LAB_ASSIGNED,
    &--PENDING_APPOINTMENT,
    &--ASSIGNED,
    &--null,
    &--true,
    &--TECH_COMPLETED,
    &--CENTER_COMPLETED,
    &--TECH_TEST_STARTED,
    &--TEST_STARTED {
      color: var(--mui-palette-secondary-color);
    }
    &--FAILED,
    &--CONSULTANT_REJECTED {
      color: #f00;
    }
    &--DEDUCTED {
      color: #fd6317;
    }
    &--CONSULTANT_PENDING,
    &--CANCELED,
    &--CERTIFICATE_EMPTY,
    &--OUTDATED {
      color: #7E5D5D;
    }
    &--NOT_ISSUED,
    &--HELD {
      color: var(--mui-palette-graySoft-color3);
    }
    &--DELIVERED_TO_DC,
    &--DELIVERED_TO_LAB {
      color: var(--mui-palette-base-darkBlue);
    }
    &--REJECTED,
    &--EXPIRED {
      color: var(--mui-palette-graySoft-color3);
    }

    &--FIELD_EXAMINATION-DELIVERING,
    &--EMPTY_LAB_ASSIGNED {
      color: var(--mui-palette-graySoft-color6);
    }

    &--LAB_FAILED {
      color: var(--mui-palette-base-status-labAssigned);
    }
    &--COLLECTED,
    &--RECIVED_BY_DC {
      color: var(--mui-palette-base-status-collected);
    }
    &--DELIVERED,
    &--RECEIVED_BY_LAB,
    &--FIELD_EXAMINATION-PENDING_APPOINTMENT,
    &--EMPTY_TESTS {
      color: var(--mui-palette-base-black);
    }
    &--FIELD_EMPTY_TESTS {
      color: var(--mui-palette-grayDark-color5);
    }

    &--FIELD_EMPTY_TESTS_PSUEDO {
      color: var(--mui-palette-graySoft-color3);
    }
    &--false {
      color: red;
    }
    &--waiting {
      color: var(--mui-palette-graySoft-color3);
    }

    &--appointment,
    &--waitingAccept,
    &--PENDING,
    &--LAB_CREATED {
      color: var(--mui-palette-base-infoDark);
    }
    &--COLLECTED_CENTER {
      color: var(--mui-palette-base-status-infoDark);
    }
    &--payment {
      color: var(--mui-palette-base-orange);
    }
    &--DELIVERING,
    &--EMPTY_TECH,
    &--EMPTY_CENTER,
    &--REQUEST_CREATED,
    &--NOT_EXPORTED_LICENSE {
      color: var(--mui-palette-base-status-orange);
    }
    &--received {
      color: var(--mui-palette-graySoft-color3);
    }
    &--undefined-date {
      color: var(--mui-palette-graySoft-color3);
      font-weight: normal !important;
    }
    &--done,
    &--EXPORTED_LICENSE {
      color: var(--mui-palette-graySoft-color3);
    }
    &--waitingCollect {
      color: var(--mui-palette-base-orangeSoft);
    }
    &--underDelivery {
      color: var(--mui-palette-base-darkBlue);
    }
    &--waitingConfirmation,
    &--FIELD_REQUEST_PENDING_APPOINTMENT {
      color: var(--mui-palette-base-purple);
    }
    &--not-found {
      color: var(--mui-palette-primary-colorSoft4);
    }
    &--not-found-date {
      color: var(--mui-palette-graySoft-color3);
      font-weight: normal !important;
    }
    &--delivery-in-progress {
      color: var(--mui-palette-base-lightBlue);
    }

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      inset-inline-start: -1.4rem;
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      transform: translateY(-50%);
      opacity: 0.5;
    }
  }

  &--APPROVED &__text::before,
  &--REFUND &__text::before,
  &--active &__text::before,
  &--IMPORTED &__text::before,
  &--USED &__text::before,
  &--undefined &__text::before,
  &--CREATED &__text::before,
  &--SUCCEED &__text::before,
  &--COMPLETED &__text::before,
  &--LAB_ASSIGNED &__text::before,
  &--PENDING_APPOINTMENT &__text::before,
  &--ASSIGNED &__text::before,
  &--null &__text::before,
  &--true &__text::before,
  &--TECH_COMPLETED &__text::before,
  &--CENTER_COMPLETED &__text::before,
  &--TECH_TEST_STARTED &__text::before,
  &--ISSUED &__text::before,
  &--TEST_STARTED &__text::before {
    background-color: var(--mui-palette-secondary-color2);
  }
  &--FAILED &__text::before {
    background-color: #f00;
  }

  &--CONSULTANT_REJECTED &__text::before,
  &--DEDUCTED &__text::before {
    background-color: #fd6317;
  }

  &--LAB_FAILED &__text::before {
    background-color: var(--mui-palette-base-status-labAssigned);
  }

  &--CERTIFICATE_EMPTY &__text::before,
  &--OUTDATED &__text::before {
    background-color: #7E5D5D;
  }
  &--CANCELED &__text::before {
    background-color: #7E5D5D;
  }
  &--PENDING_DECISION &__text::before,
  &--NOT_ISSUED &__text::before,
  &--HELD &__text::before{
    color: var(--mui-palette-graySoft-color3);
    background-color: var(--mui-palette-graySoft-color3);
  }
  &--DELIVERED &__text::before,
  &--HELD &__text::before,
  &--RECEIVED_BY_LAB &__text::before,
  &--EXPIRED &__text::before,
  &--REJECTED &__text::before,
  &--FIELD_EXAMINATION-DELIVERING &__text::before,
  &--EMPTY_LAB_ASSIGNED &__text::before,
  &--EMPTY_TESTS &__text::before,
  &--FIELD_EMPTY_TESTS_PSUEDO &__text::before {
    background-color: var(--mui-palette-base-black);
  }

  &--COLLECTED &__text::before,
  &--RECIVED_BY_DC &__text::before {
    background-color: var(--mui-palette-base-status-collected);
  }

  &--false &__text::before {
    background-color: red;
  }
  &--waiting &__text::before {
    background-color: var(--mui-palette-base-black);
  }
  &--completed &__text::before {
    background-color: var(--mui-palette-secondary-main);
  }
  &--appointment &__text::before,
  &--waitingAccept &__text::before,
  &--PENDING &__text::before,
  &--LAB_CREATED &__text::before {
    background-color: var(--mui-palette-base-infoDark);
  }
  &--COLLECTED_CENTER &__text::before {
    background-color: var(--mui-palette-base-status-infoDark);
  }
  &--payment &__text::before {
    background-color: var(--mui-palette-base-orangeDark);
  }
  &--DELIVERING &__text::before,
  &--EMPTY_TECH &__text::before,
  &--EMPTY_CENTER &__text::before,
  &--REQUEST_CREATED &__text::before,
  &--NOT_EXPORTED_LICENSE &__text::before {
    background-color: var(--mui-palette-base-status-orange);
  }
  &--CONSULTANT_PENDING &__text::before,
  &--DELIVERED_TO_DC &__text::before,
  &--DELIVERED_TO_LAB &__text::before {
    background-color: var(--mui-palette-base-darkBlue);
  }
  &--received &__text::before {
    background-color: var(--mui-palette-base-black);
  }
  &--done &__text::before,
  &--EXPORTED_LICENSE &__text::before {
    background-color: var(--mui-palette-base-black);
  }
  &--waitingCollect &__text::before {
    background-color: var(--mui-palette-base-orangeSoft);
  }
  &--underDelivery &__text::before {
    background-color: var(--mui-palette-base-darkBlue);
  }
  &--waitingConfirmation &__text::before,
  &--FIELD_REQUEST_PENDING_APPOINTMENT &__text::before {
    background-color: var(--mui-palette-base-purple);
  }

  &__tooltip {
    position: absolute;
    top: 2rem;
    left: 50%;
    transform: translateX(-50%);
    color: var(--mui-palette-base-black);
    text-align: center;
    border: 1px solid var(--mui-palette-base-infoDark);
    background: var(--mui-palette-base-white);
    width: 18rem;
    padding: 0.5rem 1.5rem;
    border-radius: 5px;
    opacity: 0;
    visibility: hidden;
    &-trigger {
      margin-left: 1px;
      position: relative;
      cursor: pointer;
    }
    &-text {
      white-space: pre-wrap;
      text-align: start;
      line-height: 1.7;
      font-size: 1.1rem;
    }
    &::before,
    &::after {
      content: "";
      position: absolute;
      top: -2rem;
      left: 50%;
      transform: translateX(-50%);
      border-style: solid;
      border-width: 10px;
      border-top-color: transparent;
      border-right-color: transparent;
      border-left-color: transparent;
    }
    &::before {
      border-bottom-color: var(--mui-palette-base-white);
      z-index: 2;
    }
    &::after {
      border-bottom-color: var(--mui-palette-base-infoDark);
      transform: translateX(-50%) translateY(-1px) scale(1.1);
    }
  }

  &__tooltip-trigger:hover &__tooltip {
    animation: showTooltip 0.1s ease forwards;
  }
}

@keyframes showTooltip {
  0% {
    opacity: 0;
    visibility: hidden;
    top: 2rem;
  }
  100% {
    opacity: 1;
    visibility: visible;
    top: 3.5rem;
  }
}
