.drilling-data {
  width: 100%;
  &__wrapper {
    margin-block: 2rem;
    width: 100%;
    padding-block: 0.6rem;
    padding-inline: 4rem;
  }
  &__item {
    margin-bottom: 3.4rem;
  }
  &__title {
    color: var(--mui-palette-primary-colorSoft13);
    font-size: 1.8rem !important;
    margin-bottom: 2rem !important;
    font-weight: 500 !important;
  }
  &__label {
    color: var(--mui-palette-grayDark-color);
    font-size: 1.6rem !important;
    margin-bottom: 3px !important;
    font-weight: 500 !important;
  }
  &__value {
    color: var(--mui-palette-primary-colorSoft16);
    font-size: 1.8rem !important;
    font-weight: 500 !important;
  }
  &__numberd {
    color: var(--mui-palette-grayDark-color8);
    font-size: 1.2rem !important;
    font-weight: 500 !important;
    display: flex;
    align-items: center;
    gap: 0.3rem;
  }

  hr {
    border: 1px solid var(--mui-palette-borders-colorSoft2);
  }
}
