.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  flex-wrap: wrap;
  gap: 2rem;
  text-align: center;
  &__display-list {
    > div {
      width: 9rem;
    }
    div[role="button"] {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.6rem;
    }
    ul[role="listbox"] {
      font-size: 1.6rem;
    }
    [role="combobox"] {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.6rem;
    }
  }
  > * {
    flex: 1;
  }
  > *:nth-child(1) {
    @media screen and (max-width: 37.5em) {
      justify-content: center;
    }
  }
  > *:nth-child(2) {
    justify-content: center;
  }
  > *:last-child {
    justify-content: flex-end;
    @media screen and (max-width: 37.5em) {
      justify-content: center;
    }
  }
  &__btn {
    font-size: 1.8rem;
    color: var(--mui-palette-base-black);
    width: 3rem;
    border-radius: 3px;
    background-color: transparent;
    text-align: center;
    cursor: pointer;
    transition: all 0.5s ease;
    &:hover {
      background-color: var(--mui-palette-background-primary);
    }
    &--active {
      background-color: var(--mui-palette-secondary-main);
      color: var(--mui-palette-base-white);
    }
  }
  &__control {
    border: 1px solid #ccc;
    height: 100%;
    display: flex;
    align-items: center;
    width: 35px;
    border-radius: 5px;
    justify-content: center;
    transform: translateY(3px);
    cursor: pointer;
  }
}
