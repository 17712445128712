@import "./styles/_custom.scss";
@import "./styles/_mixins";

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;

  @include respond(tab-land) {
    font-size: 56.25%;
  }

  @include respond(tab-port) {
    font-size: 50%;
  }

  @include respond(desktop) {
    font-size: 75%;
  }
}
body {
  margin: 0;
  font-family: "FuhosatFont", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  font-weight: 400 !important;
}

.tabs div[role="tablist"] {
  gap: 6px;
  flex-wrap: nowrap;
  button {
    width: min(17.5rem, 10vw);
  }
}

.reset-password-link {
  display: block;
  text-align: left;
  text-decoration: none;
  font-size: 1.6rem;
  color: var(--mui-palette-primary-main);
  margin-top: 5px;
}

.creat-account-link {
  text-decoration: none;
  color: var(--mui-palette-base-white);
}

.creat-account {
  text-decoration: none;
  color: var(--mui-palette-base-white);
}

.examination-dates-list {
  font-size: 1.6rem;
  font-weight: 400;
  color: var(--mui-palette-grayDark-color1);
  margin-inline-start: 1.5rem;
  margin-bottom: 7rem;
  li {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
}
.color-primary {
  color: var(--mui-palette-primary-main);
}

.color-graydark {
  color: var(--mui-palette-grayDark-color);
}
.color-white {
  color: var(--mui-palette-base-white);
}

html body .fix-location-text {
  font-size: 1.35rem !important;
}

.bold {
  font-weight: bold;
}

.img-fluid {
  max-width: 100%;
}

.img-fit {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.hidden {
  visibility: hidden;
  opacity: 0;
  width: 0;
  height: 0;
}

.d-block {
  display: block !important;
}
.d-flex {
  display: flex !important;
}
.d-none {
  display: none !important;
}

.request-data-details {
  &__wrapper {
    margin-block: 2rem;
  }
  &__title {
    color: var(--mui-palette-grayDark-color);
    font-size: 1.8rem !important;
    margin-bottom: 2rem !important;
    font-weight: 500 !important;
  }
  &__label {
    color: var(--mui-palette-black);
    font-size: 1.4rem !important;
    margin-bottom: 3px !important;
    font-weight: 500 !important;
  }
  &__value {
    color: var(--mui-palette-primary-colorSoft4);
    font-size: 1.6rem !important;
    font-weight: 400 !important;
    text-align: right;
    margin-right: auto;
    word-break: break-all;
  }
  &__link {
    color: var(--mui-palette-primary-primary);
    font-size: 1.6rem !important;
    font-weight: 500 !important;
    cursor: pointer;
  }

  hr {
    border: 1px solid var(--mui-palette-borders-colorSoft2);
  }
}

.invalid-text {
  color: var(--mui-palette-base-dangerText);
}

hr {
  border: 1px solid var(--mui-palette-borders-colorSoft2);
}

.restore-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;

  &__img path {
    stroke: var(--mui-palette-primary-main);
  }

  &__text {
    color: var(--mui-palette-primary-main);
  }

  &:disabled &__img path {
    stroke: var(--mui-palette-graySoft-color);
  }

  &:disabled &__text {
    color: var(--mui-palette-graySoft-color);
  }
}

.submenu-children-list-wrapper {
  transform-origin: top;
  transform: scaleY(0);
  height: 0;
}
.submenu-children-list-wrapper--active {
  transform-origin: top;
  transform: scaleY(1);
  height: 100%;
}

.en-text {
  direction: ltr;
  text-align: right;
}

.input-disabled {
  font-family: "FuhosatFont", sans-serif;
  font-weight: 500;
  font-size: 1.4rem;
  background-color: var(--mui-palette-background-colorSoft2);
  opacity: 1;
  color: #525252cc;
  cursor: not-allowed;
}

.svg-text-fix {
  text {
    transform: translateX(4.5rem);
  }
}
