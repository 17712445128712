.table-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  background: rgba(255, 255, 255, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: absolute;
    z-index: 99;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &__text {
    font-size: 1.6rem !important;
    font-weight: bold !important;
    color: var(--mui-palette-grayDark-color);
  }

  &__overlay {
    filter: blur(10px);
  }
}
