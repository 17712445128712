.reset-password-viewer {
  &__checker {
    font-size: 1.4rem !important;

    color: var(--mui-palette-graySoft-color2);
    &--valid {
      color: var(--mui-palette-base-valid) !important;
    }
    &--invalid {
      color: var(--mui-palette-base-invalid) !important;
    }
  }
}
