$timeline-bullets-size: 3rem;

.vertical-timeline {
  &:has(div.hide) {
    span {
      display: none;
    }
    .vertical-timeline__list {
      & > *::before {
        display: none;
      }
    }
  }

  position: relative;
  > span {
    position: absolute;
    top: 1px;
    inset-inline-start: calc(-#{$timeline-bullets-size} - 0.6rem);
    transform: translate(0, 0);
    background-image: linear-gradient(
      to bottom,
      var(--mui-palette-graySoft-color5) 50%,
      transparent 0%
    );
    background-size: 1px 10px;
    width: 1px;
    z-index: 1;
  }
  &__list {
    counter-reset: timeline;
    & > * {
      position: relative;
      &:not(:last-child) {
        margin-bottom: 3rem;
      }
      &:first-of-type::before {
        border: 1px solid var(--mui-palette-secondary-main) !important;
        color: var(--mui-palette-secondary-main);
      }

      &::before {
        content: counter(timeline);
        position: absolute;
        inset-inline-start: calc(-#{$timeline-bullets-size} - 2rem);
        top: 0;
        width: $timeline-bullets-size;
        height: $timeline-bullets-size;
        counter-increment: timeline;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid var(--mui-palette-base-black);
        background-color: var(--mui-palette-base-white);
        border-radius: 50%;
        font-size: 2rem;
        z-index: 2;
      }
    }
  }
}

.timeline-stage-active::before {
  border: 1px solid var(--mui-palette-secondary-main) !important;
  color: var(--mui-palette-secondary-main) !important;
}
.timeline-stage-done::before {
  border: 1px solid var(--mui-palette-secondary-main) !important;
  color: var(--mui-palette-base-white) !important;
  background-color: var(--mui-palette-secondary-main) !important;
}
