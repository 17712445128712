%inputStyles {
  position: relative !important;
  border-radius: 5px !important;
  font-size: 1.4rem !important;

  width: 100% !important;
  display: block !important;
  height: 4rem !important;
  background-color: var(--mui-palette-background-primary) !important;
  border: 1px solid var(--mui-palette-borders-primary) !important;
  padding: 10px 8px !important;

  &:focus {
    outline: 1px solid var(--mui-palette-borders-primary) !important;
  }

  &::-webkit-input-placeholder {
    color: var(--mui-palette-graySoft-color) !important;
    font-weight: 600 !important;
    font-size: 1.6rem !important;
    font-family: "Tahoma", Courier, monospace !important;
    @media screen and (max-width: 60rem) {
      font-size: 1rem !important;
    }
  }
}

.wpwl-form {
  max-width: initial !important;
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  direction: ltr !important;
  text-align: end !important;
}

.wpwl-wrapper {
  input {
    @extend %inputStyles;
  }

  &-brand {
    margin-right: 1rem !important;
    display: none;
  }
}

.wpwl-group {
  font-size: 1.6rem !important;
  margin-bottom: 3rem !important;

  &-brand {
    display: none !important;
  }

  &-cardNumber,
  &-cardHolder {
    width: 100% !important;
    padding: 0 !important;
  }

  &-cardNumber {
    width: 100% !important;
    padding: 0 !important;
  }

  &-cardHolder {
    width: 100% !important;
    padding: 0 !important;
  }

  &-expiry {
    width: 48% !important;
    margin-left: 2%;
  }

  &-cvv {
    width: 48% !important;
    margin-right: 2%;
  }

  &-submit {
    text-align: center !important;
    margin-top: 8rem !important;
  }
}

.wpwl-control {
  &-cardNumber,
  &-cvv {
    @extend %inputStyles;
  }
  &-cardHolder {
    text-align: end !important;
  }

  &-expiry {
    text-align: end !important;
  }
}

.wpwl-label {
  font-family: "FuhosatFont", sans-serif !important;
  line-height: 1.4375em !important;
  padding: 0 !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  color: var(--mui-palette-grayDark-color) !important;
  font-size: 1.4rem !important;
  font-weight: 500 !important;
  margin-bottom: 1rem !important;

  &-brand {
    margin-bottom: 0 !important;
  }
}

.wpwl-button-pay {
  float: none !important;
  padding: 6px 16px !important;
  width: 28.1rem !important;
  height: 5rem !important;
  min-width: 20rem !important;
  font-size: 1.6rem !important;
  font-weight: bold !important;
  background-image: linear-gradient(
    234deg,
    var(--mui-palette-primary-main) 70%,
    var(--mui-palette-secondary-main) 100%
  );
  color: white !important;
  background-size: 200%;
  transition: all 0.5s ease;
  &:hover {
    background-position: right !important;
  }
}

.wpwl-button-error[disabled] {
  background-image: linear-gradient(
    234deg,
    var(--mui-palette-primary-main) 70%,
    var(--mui-palette-secondary-main) 100%
  );
  border: none !important;
}

.wpwl-brand-card {
  margin: 0 !important;
  margin-right: auto !important;
}
