.otp {
  &__item {
    height: 13.3rem;
  }
  &__item div {
    overflow: hidden;
  }
  &__item {
    background: var(--mui-palette-background-primary);
    border-radius: 5px;
    height: 9rem;
    // width: 7rem;
    input {
      color: var(--mui-palette-primary-main);
      font-weight: bold;
      // font-size: 5.6rem !important;
      height: 100% !important;

      &:focus,
      &:hover {
        outline: none !important;
      }
    }
    input::placeholder {
      color: var(--mui-palette-primary-main);
      opacity: 1;
    }
  }

  &__item--expired {
    background: var(--mui-palette-base-danger) !important;
    border: 1px solid var(--mui-palette-base-dangerBorder) !important;
    input {
      color: var(--mui-palette-base-dangerText) !important;
    }
  }

  &__form {
    & .MuiInputBase-root {
      height: 100% !important;
    }
  }
}
