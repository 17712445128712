.mob-sidemenu {
  text-align: center;
  transition: all 0.5s ease;
  &--hide {
    transform: translateX(28rem);
  }
  &__list {
    display: flex;
    justify-content: center;
    flex-direction: column;
    &-item {
      gap: 5px;
    }
    &-label {
      margin-bottom: 0 !important;
    }
  }
  &__link {
    &--active {
      background-color: var(--mui-palette-base-white);
      color: var(--mui-palette-primary-main) !important;
      border-radius: 7px;
      span {
        color: var(--mui-palette-primary-main) !important;
      }
    }
  }
  &__user-data {
    margin-bottom: 4rem;
    p {
      font-size: 1.6rem;
      font-weight: bold;
      color: var(--mui-palette-base-white);
      &:not(:last-child) {
        margin-bottom: 1.6rem;
      }
    }
  }
  &__logout {
    &::before {
      left: 0;
    }
  }
}
