.base-table-double {
  display: grid;

  &__header {
    display: grid;
    grid-template-columns: subgrid;
    grid-column: 1 / -1;
  }
  &__body {
    display: grid;
    grid-template-columns: subgrid;
    grid-column: 1 / -1;
  }

  &__body-item {
    display: grid;
    grid-column: 1 / -1;
    grid-template-columns: subgrid;
  }

  &__body-item-unset {
    display: grid;
    grid-column: 1 / -1;
    grid-template-columns: subgrid;
  }
  &__body-item--empty {
    margin-bottom: 1rem;
    margin-top: -1rem;
    grid-column: 1 / -1;
    height: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    border-radius: 3px;
    background: #41958e00;
    border: 1px solid #3f777340;
  }
  @media only screen and (max-width: 500px) {
  &__body-item-unset {
    grid-template-columns: unset !important;
    }
  }
}
