.long-length-container {
  position: relative;
  display: inline-block;
  cursor: default;
  font-size: 1.6rem;
  direction: ltr;

  .custom-tooltip-trigger {
    display: inline-block;
    cursor: pointer;
  }

  .custom-tooltip {
    position: absolute;
    top: 100%;
    right: 5%;
    padding: 8px;
    background-color: var(--mui-palette-borders-colorSoft2);
    color: var(--mui-palette-grayDark-color5);
    font-size: 14px;
    border-radius: 4px;
    z-index: 1;
    opacity: 2;
    transform: translateY(-10px);
    transition: opacity 0.3s, transform 0.3s;
    width: max-content;
    @media screen and (max-width: 40rem) {
      width: 20rem;
      font-size: 10px;
    }
  }

  &:hover .custom-tooltip {
    opacity: 1;
    transform: translateY(0);
  }
}
