.prev-button {
  color: var(--mui-palette-primary-main);
  background-color: transparent;
  font-size: 1.6rem;
  font-weight: 500;
  font-family: "FuhosatFont", sans-serif;
  cursor: pointer;
  border: none;
  transition: all 0.5s ease;
  &:hover {
    color: var(--mui-palette-secondary-main);
  }
}
