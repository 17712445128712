.checkup-timeline {
  position: relative;
  width: 100%;

  &__line {
    position: absolute;
    top: 50%;
    inset-inline-start: 0;
    transform: translate(0, -50%);
    background-image: linear-gradient(
      to right,
      var(--mui-palette-graySoft-color5) 50%,
      transparent 0%
    );
    background-size: 10px 3px;
    height: 1px;
    width: 100%;
    z-index: 1;
    &--active {
      border: 2px solid var(--mui-palette-secondary-main);
      width: 0%;
      z-index: -1;
      transition: width 0.5s ease;
    }
  }
  &__wrapper {
    position: relative;
    z-index: 3;
  }
  &__circle {
    width: 3.2rem;
    height: 3.2rem;
    border-radius: 50%;
    background: var(--mui-palette-base-white);
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--mui-palette-grayDark-color3);
    z-index: 2;
    font-size: 20px;
    font-weight: bold;
    position: relative;
    overflow: hidden;
  }
  &__icon-done {
    position: absolute;
    top: 0;
    inset-inline-end: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease;
    transform: scale(0);
  }
  &__wrapper--active &__circle {
    border: 1px solid var(--mui-palette-secondary-main);
    color: var(--mui-palette-secondary-main);
  }
  &__wrapper--done &__circle {
    border: 1px solid var(--mui-palette-secondary-main);
  }
  &__wrapper--done &__icon-done {
    background: var(--mui-palette-secondary-main);
    border: 1px solid var(--mui-palette-secondary-main);
    transform: scale(1);
  }
  &__label {
    position: absolute;
    width: max-content;
    inset-inline-start: -100%;
    top: 110%;
    font-size: 1.4rem !important;
    color: var(--mui-palette-grayDark-color);
    &--sm {
      inset-inline-start: -50%;
    }
  }
}
